import { isMobile } from "react-device-detect";
import "../components/Card.scss";

function Card({ title, link, sticker, classType }) {
  return (
    <div className="card_parent">
      <a className="card" href={link} target="_blank">
        <h2>{title}</h2>
      </a>
      {isMobile ? <></> : <img src={sticker} className={`${classType}`} />}
    </div>
  );
}

export default Card;
