import "./App.scss";
import Card from "./components/Card";

import github from "./assets/github.svg";
import instagram from "./assets/instagram.svg";
import linkedin from "./assets/linkedin.svg";
import twitter from "./assets/twitter.svg";

import portfolio from "./assets/portfolio.png";
import gt from "./assets/gt.png";
import lk from "./assets/lk.png";
import cv from "./assets/cv.png";

function App() {
  return (
    <div className="app">
      <section>
        <div className="header">
          <p>IT'S ME,</p>
          <h1>marc lópez</h1>
        </div>
        <div className="card_group">
          <Card
            title={"PORTFOLIO"}
            link={"https://marclopez.oddsolutionslab.com/"}
            sticker={portfolio}
            classType={"sticker_left"}
          />
          <Card
            title={"GITHUB"}
            link={"https://github.com/marclopezsoler"}
            sticker={gt}
            classType={"sticker_right"}
          />
          <Card
            title={"LINKEDIN"}
            link={
              "https://www.linkedin.com/in/marc-l%C3%B3pez-soler-aab6b81a3/"
            }
            sticker={lk}
            classType={"sticker_left2"}
          />
          <Card
            title={"CV"}
            link={"https://marclopez.oddsolutionslab.com/CV_2023_MarcLopez.pdf"}
            sticker={cv}
            classType={"sticker_right2"}
          />
        </div>
        <div className="footer">
          <div className="icons">
            <a>
              <img src={instagram} />
            </a>
            <a>
              <img src={twitter} />
            </a>
            <a>
              <img src={github} />
            </a>
            <a>
              <img src={linkedin} />
            </a>
          </div>
          <a
            className="contact"
            href="mailto:marcls.arbucies@gmail.com"
            target="_blank"
          >
            <p>CONTACT</p>
          </a>
        </div>
      </section>
    </div>
  );
}

export default App;
